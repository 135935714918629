import {drawMap, resizeMap} from "./travelmap";

document.addEventListener('DOMContentLoaded', function () {
    if (typeof visited_countries === "undefined") {
        return;
    }
    window.svg_travel_map = drawMap("map_canvas", visited_countries);
    resizeMap(window.svg_travel_map, 'map_wrapper');
});

window.addEventListener('resize', function () {
    if (typeof window.svg_travel_map === "undefined") {
        return;
    }
    resizeMap(window.svg_travel_map, 'map_wrapper');
});
